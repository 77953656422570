<template>
  <header>
    <div
      :class="['header-layer', { active: isActive }]"
      @click="hideMenu"
    ></div>
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-6 align-self-center">
          <a href="#" class="logo">
            <img src="../assets/images/logo-mfc.png" alt="logo" />
          </a>
        </div>
        <div class="col-md-9 col-6 align-self-center text-end">
          <div :class="['navbar-menu', { active: isActive }]">
            <!-- <div class="mobile-menu">
              <div class="row">
                <div class="col-9 align-self-center">
                  <a href="#" class="logo">
                    <img src="../assets/images/logo-mfc.png" alt="logo" />
                  </a>
                </div>
                <div class="col-3 align-self-center text-end">
                  <div class="close-icon" @click="hideMenu">
                    <i class="fa fa-x"></i>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <ul class="menu">
              <li class="active"><a href="#" class="medium">Home</a></li>
              <li><a href="#" class="medium">Header</a></li>
              <li><a href="#" class="medium">Banner</a></li>
              <li><a href="#" class="medium">Card</a></li>
              <li><a href="#" class="medium">Button</a></li>
            </ul> -->
          </div>
          <div class="menu-wrapper">
            <div class="btn-wrapper">
              <a href="#contact" class="btn-style small">Contact</a>
            </div>
            <!-- <div class="hamburger-menu">
              <img
                src="../assets/images/icons/bar-icon.svg"
                alt="icon"
                @click="showMenu"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    showMenu() {
      this.isActive = true; // Show menu
    },
    hideMenu() {
      this.isActive = false; // Hide menu
    },
  },
};
</script>
