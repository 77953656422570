
<template>
  <footer>
    <div class="footer-menu-wrapper">
      <div class="menu">
        <!-- <ul>
          <li><a href="#">ABOUT MFMC</a></li>
          <li><a href="#">PROJECTS</a></li>
          <li><a href="#">WHY INVEST</a></li>
          <li><a href="#">CONTACT</a></li>
        </ul> -->
        <!-- <a href="mailto:investments@mfmc.mv"
          ><p class="text-white">investments@mfmc.mv</p></a
        > -->
      </div>
      <div class="row">
        <div class="col-7 align-self-center">
          <div class="social-links">
            <ul>
              <li>
                <a href="#"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-youtube"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-5 align-self-center text-end">
          <a href="#" class="logo">
            <img src="../assets/images/logo-mfc.png" alt="img" />
          </a>
        </div>
      </div>
    </div>
    <div class="footer-bootom">
      <div class="menu">
        <ul>
          <li><a href="#">Privacy Policy</a></li>
          <li><a href="#">Terms of Use</a></li>
          <li><a href="#">Cookie Policy</a></li>
        </ul>
      </div>
      <div class="copy-right">
        <p class="small">
          <strong>© 2024 MFMC</strong> <br />
          This site is protected by reCAPTCHA and the Google Privacy Policyand
          Terms of Service apply.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
