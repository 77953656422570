<template>
  <HeaderComponent />

  <section class="sec-banner">
    <div class="container">
      <div class="content-wrapper">
        <h1 class="century">
          Invest in the <br />
          future of Paradise
        </h1>
      </div>
    </div>
  </section>

  <section class="sec-prosperity">
    <div class="content-wrapper">
      <h3 class="inter">
        Championing Maldivian <br />
        prosperity and independence through <br />
        strategic investments
      </h3>
      <p>
        Maldives Fund Management Corporation (MFMC) offers unique investment
        opportunities that leverage the nation's status as the world's number 1
        luxury destination.
      </p>
    </div>
    <div class="bg-black">
      <p>
        Your gateway to exclusive investment opportunities in the Maldives - a
        millennia-old trade hub and the world's premier luxury destination
      </p>
    </div>
  </section>

  <section class="development-project">
    <Projects />
  </section>

  <section class="sec-contact-form">
    <ContactUs />
  </section>

  <section class="sec-news-letter">
    <div class="container">
      <div class="content-wrapper">
        <p>
          Sign up for our Newsletter for updates <br />
          about MFMC’s Project
        </p>
        <div class="form">
          <div class="form-group">
            <label for="search"></label>
            <div class="search-wrapper">
              <input
                type="text"
                id="search"
                class="form-control"
                placeholder="youremail@website.com"
              />
              <div class="btn-wrapper">
                <a href="#" class="btn-style small">Sign up</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <FooterVComponent />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import FooterVComponent from "@/components/FooterVComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import ContactUs from "@/components/ContactUs.vue";
import Projects from "@/components/Projects.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    FooterVComponent,
    HeaderComponent,
    ContactUs,
    Projects,
  },
});
</script>
