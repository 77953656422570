<template>
  <div class="development-project-wrapper">
    <div
      v-for="(project, index) in projects"
      :key="index"
      class="development-project-card"
      :class="{ active: expandedCardIndex === index }"
      :style="getCardBackground(project.image)"
    >
      <div class="content-wrapper">
        <div class="project-header">
          <div class="row">
            <div class="col-8 align-self-center">
              <div class="content">
                <h3>{{ project.title }}</h3>
              </div>
            </div>
            <div class="col-4 align-self-center">
              <div class="btn-wrapper">
                <a
                  href="#"
                  class="btn-style transparent"
                  @click.prevent="toggleCard(index)"
                  >+ Read More</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="expandedCardIndex === index" class="project-content">
          <ul class="detail">
            <li>Location: {{ project.location }}</li>
            <li>Type of Project: {{ project.type }}</li>
          </ul>
          <p>{{ project.description }}</p>
          <div class="investment-required-card">
            <p>Investment Required:</p>
            <p>The project will be carried out in three phases.</p>
            <ul>
              <li v-for="(phase, i) in project.investment" :key="i">
                {{ phase }}
              </li>
            </ul>
          </div>
          <div class="btn-wrapper">
            <a :href="project.file" class="btn-style" downloads>
              <img
                src="../assets/images/icons/download-btn-icon.svg"
                alt="icon"
              />
              DOWNLOAD
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

interface Project {
  title: string;
  location: string;
  type: string;
  description: string;
  investment: string[];
  image: string;
  file?: string;
}

export default defineComponent({
  name: "ProjectsComponent",
  setup() {
    const expandedCardIndex = ref<number | null>(null);

    const projects = ref<Project[]>([
      {
        title: "Gaafaru Integrated Tourism Development Project",
        location: "Kaafu Atoll, Maldives",
        type: "Integrated Tourism Development",
        description: `
      Located 60KM north of Male’, the Gaafaru Integrated Tourism Development Project is one of its kind gateways to ultimate luxury and seclusion. 
      The project aims to introduce an unprecedented level of ultra-luxury real estate to the Maldives, featuring 14 private islands and two luxury 
      resorts within a single lagoon. Each private island, ranging from 2 to 4 hectares in size, will be strategically positioned to ensure maximum 
      privacy and seclusion for future residents. The resorts, developed on a reclaimed area of 19 hectares, will include a mix of villas and residences 
      with amenities synonymous with Maldivian hospitality. The project also includes a 35-hectare expansion of Gaafaru Island to accommodate population 
      growth and future developments. The concept vision master plan for this project was developed by Foster + Partners, focusing on climate adaptation, 
      sustainability, and community integration within the Gaafaru Atoll.
    `,
        investment: [
          "Phase 1: USD 179.8 million",
          "Phase 2: USD 262.0 million",
          "Phase 3: USD 588.2 million",
        ],
        image: "gaafaru.webp",
        file: "/files/gaafaru.pdf",
      },
      {
        title: "Hankede Integrated Tourism Development Project",
        location: "Addu Atoll, South Maldives",
        type: "Integrated Tourism Development",
        description: `
      The Hankede Integrated Tourism Development Project aims to transform the southernmost tip of the Maldives into a premier tourist destination. 
      The project includes a mix of luxurious hotels, boutique hotels, serviced apartments, and beach villas. Additionally, it will feature world-class 
      dining, retail stores, wellness centers, and a variety of entertainment facilities, ensuring a comprehensive and unique tourism experience.
    `,
        investment: ["Total USD 142.9 million funding secured"],
        image: "hankede.webp",
        file: "/files/hankede.pdf",
      },
      {
        title: "Addu Nature Park",
        location: "Addu City, Maldives",
        type: "Ecotourism",
        description: `
      Situated in the southern Maldives, Addu Nature Park is the largest nature reserve and a tranquil sanctuary that highlights the region's unique biodiversity. 
      The park is known for its beautiful piers extending into lakes, the presence of mangroves, and scenic cycling routes winding through lush wetland vegetation. 
      The project involves the development of 2 resorts consisting of Luxury Eco Villas and Lodge Style Villas built and operated with a primary focus on sustainability. 
      The project will also feature Visitor Attractions and Conservation Facilities.
    `,
        investment: ["USD 26 million"],
        image: "addu.webp",
        file: "/files/addu-nature-park.pdf",
      },
      {
        title: "Dhiyaneru Safari Harbor Development Project",
        location: "Kaafu Atoll, Maldives",
        type: "Harbor Development",
        description: `
      This project aims to develop a yacht marina facility, the first of its kind in the Maldives, specifically designed to accommodate the needs of super yachts visiting 
      the area. Alongside this, the harbor will feature modern floating docks and efficient trot mooring systems envisioned to support the entire fleet of active local 
      safari vessels currently residing in the Maldives. The lagoon, encompassing four islands, will be developed to include an international yacht club, a city hotel 
      with multiple amenities targeted to tourists and locals, and an island offering various support services such as fueling stations, power and water supplies, and 
      waste management. Additionally, one of the islands will be transformed into a vibrant picnic spot for both tourists and locals visiting the tourism zone.
    `,
        investment: ["USD 120 million"],
        image: "dhiyaneru.webp",
        file: "/files/dhiyaneru.pdf",
      },
    ]);

    const toggleCard = (index: number) => {
      expandedCardIndex.value =
        expandedCardIndex.value === index ? null : index;

      // After toggling, navigate to the projects section
      setTimeout(() => {
        window.location.hash = "#projects";
      }, 300);
    };

    const getCardBackground = (image: string) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const imagePath = require(`@/assets/images/${image}`);
      return {
        background: `url(${imagePath}) no-repeat center`,
        backgroundSize: "cover",
      };
    };

    return {
      expandedCardIndex,
      projects,
      toggleCard,
      getCardBackground,
    };
  },
});
</script>