<template>
  <section id="contact" class="sec-contact-form">
    <div class="form">
      <h3>Interested to get in touch? Register with us!</h3>
      <form @submit.prevent="sendEmail">
        <div class="form-group">
          <label for="name"></label>
          <input
            type="text"
            id="name"
            class="form-control"
            placeholder="Full Name"
            v-model="form.name"
            @blur="validateField('name')"
          />
          <span v-if="errors.name" class="text-red-500 text-sm">{{
            errors.name
          }}</span>
        </div>
        <div class="form-group">
          <label for="designation"></label>
          <input
            type="text"
            id="designation"
            class="form-control"
            placeholder="Designation"
            v-model="form.designation"
            @blur="validateField('designation')"
          />
          <span v-if="errors.designation" class="text-red-500 text-sm">{{
            errors.designation
          }}</span>
        </div>
        <div class="form-group">
          <label for="company"></label>
          <input
            type="text"
            id="company"
            class="form-control"
            placeholder="Company"
            v-model="form.company"
            @blur="validateField('company')"
          />
          <span v-if="errors.company" class="text-red-500 text-sm">{{
            errors.company
          }}</span>
        </div>
        <div class="form-group">
          <label for="company-type"></label>
          <select
            id="company-type"
            class="form-control"
            v-model="form.company_type"
            @blur="validateField('company_type')"
          >
            <option value="">Select Company Type</option>
            <option value="Developer">Developer</option>
            <option value="Financier">Financier</option>
            <option value="Operator">Operator</option>
            <option value="Designer">Designer</option>
            <option value="Consultant">Consultant</option>
            <option value="Others">Others</option>
          </select>
          <span v-if="errors.company_type" class="text-red-500 text-sm">{{
            errors.company_type
          }}</span>
        </div>

        <div class="form-group">
          <label for="email"></label>
          <input
            type="email"
            id="email"
            class="form-control"
            placeholder="Email"
            v-model="form.email"
            @blur="validateField('email')"
          />
          <span v-if="errors.email" class="text-red-500 text-sm">{{
            errors.email
          }}</span>
        </div>
        <div class="form-group">
          <label for="contact"></label>
          <input
            type="tel"
            id="contact"
            class="form-control"
            placeholder="Contact Number"
            v-model="form.contact"
            @blur="validateField('contact')"
          />
          <span v-if="errors.contact" class="text-red-500 text-sm">{{
            errors.contact
          }}</span>
        </div>
        <div class="form-group">
          <label for="interested-project" class="text-white text-center">
            Interested Projects
          </label>
          <div class="pill-select-wrapper items-center text-center">
            <span
              v-for="project in interestedProjects"
              :key="project"
              :class="[
                'pill',
                form.interested_project.includes(project) ? 'selected' : '',
              ]"
              @click="toggleProject(project)"
            >
              {{ project }}
            </span>
          </div>
          <span v-if="errors.interested_project" class="text-red-500 text-sm">{{
            errors.interested_project
          }}</span>
        </div>

        <div class="form-group">
          <div class="btn-wrapper">
            <span v-if="loading">
              <svg
                class="animate-spin h-5 w-5 mr-3 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
              Sending...
            </span>
            <span v-else>
              <button type="submit" class="btn-style">Register Interest</button>
            </span>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import axios from "axios";

export default defineComponent({
  name: "ContactUs",
  setup() {
    const form = reactive({
      name: "",
      designation: "",
      company: "",
      company_type: "",
      email: "",
      contact: "",
      interested_project: [] as string[], // Changed to array for multi-select
    });

    const interestedProjects = [
      "Gaafaru",
      "Hankede",
      "Addu Nature Park",
      "Dhiyaneru",
    ];

    const errors = reactive<Record<string, string | null>>({
      name: null,
      designation: null,
      company: null,
      company_type: null,
      email: null,
      contact: null,
      interested_project: null,
    });

    const loading = ref(false);

    const validateField = (field: keyof typeof form) => {
      if (!form[field]) {
        errors[field] = `${field.replace(/_/g, " ")} is required.`;
      } else if (field === "email" && !/\S+@\S+\.\S+/.test(form.email)) {
        errors.email = "Please enter a valid email address.";
      } else {
        errors[field] = null;
      }
    };

    const toggleProject = (project: string) => {
      const index = form.interested_project.indexOf(project);
      if (index > -1) {
        form.interested_project.splice(index, 1); // Remove if already selected
      } else {
        form.interested_project.push(project); // Add to selection
      }
    };

    const validateForm = () => {
      let isValid = true;
      Object.keys(form).forEach((field) => {
        validateField(field as keyof typeof form);
        if (errors[field]) {
          isValid = false;
        }
      });
      return isValid;
    };

    const sendEmail = async () => {
      if (!validateForm()) {
        return;
      }

      loading.value = true;

      try {
        // Convert the interested_project array to a comma-separated string
        const formData = {
          ...form,
          interested_project: form.interested_project.join(", "), // Convert array to string
        };

        const response = await axios.post("/send-email", formData);
        alert(response.data.message);
        resetForm();
      } catch (error) {
        const err = error as any;
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).forEach((key) => {
            errors[key] = err.response.data.errors[key][0];
          });
        } else {
          alert("Failed to send the email.");
        }
      } finally {
        loading.value = false;
      }
    };

    const resetForm = () => {
      Object.keys(errors).forEach((key) => {
        errors[key] = null;
      });
    };

    return {
      form,
      errors,
      sendEmail,
      validateField,
      toggleProject,
      loading,
      interestedProjects,
    };
  },
});
</script>

<style scoped>
.text-red-500 {
  color: #f56565;
}

.pill-select-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pill {
  padding: 8px 12px;
  border-radius: 10px;
  background-color: #96a5b2;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
  color: #343d48;
}

.pill.selected {
  background-color: #3490dc;
  color: white;
}
</style>
